import React, { useState, useEffect, useContext } from 'react';
import Header from '../../Components/Header/Header';
import '../../app.css'
import '../Ads/Ads.css';
import { getCookieValue, Strings } from '../../utilities/constants';
import 'quill/dist/quill.snow.css'
import AdsOverview from './AdsOverview'
import AdsGallery from './AdsGallery'
import AdTechicleDetails from './AdsTechnicleDetails';
import AdsVehicleDetails from './AdsVehicleDetails';
import AdBasicDetails from './AdBasicDetails';
import { useLocation } from 'react-router-dom';
import { DataContext } from '../../utilities/DataContext';
import { trackPageView } from '../../hooks/analytics';



const AdsMainPage = () => {

  const { addDetails, tab, setTab } = useContext(DataContext);
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const location = useLocation();
  const addid = location.state?.id ? location.state?.id : !isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_ad_overview_data) ? addDetails.vehicle_ad_overview_data.id : ''
  const [selectedTab, setSelectedTab] = useState('overview');
  const [tabOverviewChanged, setTaboverviewChanges] = useState(false);
  const [tabDetailChanged, setDetailTabChanges] = useState(false);
  const [tabGalleryChanged, setGalleryTabChanges] = useState(false);
  const [tabTechicalDataChanged, setTechnicleTabChanges] = useState(false);
  const [tabChanged, setTabChanged] = useState('');

  useEffect(() => {
    window.dataLayer.push({
      'event': 'gtag.init',
      'gtag.config': {
        trackingId: 'G-4CG75M28S5',
        send_page_view: false
      }
    });
  }, [window.location.pathname])

  useEffect(() => {
    document.title = 'Anzeige erstellen';
    const consentCookie = getCookieValue('cookie_consent_level');
    if (consentCookie) {
      const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
      console.log('consentStatus', consentStatus.tracking);
      if (consentStatus.tracking) {
        trackPageView(window.location.pathname, 'Anzeige erstellen');
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      setTab('')
    };
  })




  const renderTabContent = () => {
    switch (selectedTab) {
      case 'overview':
        return <AdsOverview addId={addid} onNextPress={() => setTabchange('details')} tabchanged={tabChanged} onClick={changeTab} />;
      case 'details':
        return <AdsVehicleDetails addId={addid} onNextPress={() => { setTabchange('gallery') }} tabchanged={tabChanged} onBackPress={() => setTabchange('overview')} onClick={changeTab} />;
      case 'gallery':
        return <AdsGallery addId={addid} onBackPress={() => setTabchange('details')} tabchanged={tabChanged} onNextPress={() => setTabchange('technicalData')} onClick={changeTab} />;
      case 'technicalData':
        return <AdTechicleDetails addId={addid} onBackPress={() => setTabchange('gallery')} tabchanged={tabChanged} onNextPress={() => setTabchange('baseVehicle')} onClick={changeTab} />;
      case 'baseVehicle':
        return <AdBasicDetails addId={addid} onBackPress={() => setTabchange('technicalData')} tabchanged={tabChanged} onClick={changeTab} />;
      default:
        return null;
    }
  };


  const changeTab = () => {
    setSelectedTab(tabChanged);

  }

  const setTabchange = (tabName) => {
    setTab(tabName)
    setTabChanged(tabName)
    // setTimeout(() => {
    //   // setTaboverviewChanges(tabName == 'overview');
    //   // setDetailTabChanges(tabName == 'details');
    //   // setGalleryTabChanges(tabName == 'gallery');
    //   // setTechnicleTabChanges(tabName == 'technicalData');
    //   // setBasicabChanges(tabName == 'baseVehicle');
    //   // Update the selectedTab state with the clicked tabName
    //   setSelectedTab(tabName);
    //   setTabChanged(tabName)

    // }, 1000);
    // Update the corresponding state based on the tabName

  };


  return (
    <>
      <Header />
      <div className='maincontainer'>
        <h1 className={'add_headertext3 mb2'}>{Strings.create_add}</h1>
        <div className="tabs">
          <button className={`${selectedTab === 'overview' ? 'active-btn' : 'inactive-btn'}`} onClick={() => { setTabchange('overview') }}>Überblick</button>
          <button className={`${selectedTab === 'details' ? 'active-btn' : 'inactive-btn'}`} onClick={() => {
            setTabchange('details')
          }}>Fahrzeug Details</button>
          <button className={`${selectedTab === 'gallery' ? 'active-btn' : 'inactive-btn'}`} onClick={() => setTabchange('gallery')}>Galerie</button>
          <button className={`${selectedTab === 'technicalData' ? 'active-btn' : 'inactive-btn'}`} onClick={() => setTabchange('technicalData')}>Technische Daten</button>
          <button className={`${selectedTab == 'baseVehicle' ? 'active-btn' : 'inactive-btn'}`} onClick={() => setTabchange('baseVehicle')}>Basis Fahrzeug</button>
        </div>

        <div className="tab-content">
          {renderTabContent()}
        </div>
      </div>

    </>
  )

}

export default AdsMainPage