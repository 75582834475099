import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import '../UserPage/UserSelectionPage.css'
import '../../app.css'
import Footer from '../../Components/Footer/Footer';
import { getCookieValue, Strings } from '../../utilities/constants';
import { DataContext } from '../../utilities/DataContext';
import { trackPageView } from '../../hooks/analytics';

const UserSelectionPage = () => {
    const navigate = useNavigate();
    const { setUserType, userType } = useContext(DataContext)

    useEffect(() => {
        window.dataLayer.push({
            'event': 'gtag.init',
            'gtag.config': {
                trackingId: 'G-4CG75M28S5',
                send_page_view: false
            }
        });
    }, [window.location.pathname])


    useEffect(() => {
        document.title = 'Registrierung Business/Privat';
        const consentCookie = getCookieValue('cookie_consent_level');
        if (consentCookie) {
            const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
            console.log('consentStatus', consentStatus.tracking);
            if (consentStatus.tracking) {
                trackPageView(window.location.pathname, 'Registrierung Business/Privat')
            }
        }
    }, [])



    const gotoRegistration = (type) => {
        if (type === 'company') {
            navigate('/registration')
            setUserType('business-user')
        } else {
            navigate('/private_user_registration')
            setUserType('private-user')
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: 'white', height: '15vmin' }}>
                <img
                    src={require("../../../src/assets/logo.webp")}
                    alt='logo'
                    className="d-inline-block align-top"
                    onClick={() => { navigate('/home') }}
                />
            </div>
            <div style={{ backgroundColor: 'white', }} >
                <div style={{
                    padding: '10vh',
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/reg_back.jpg"})`, backgroundPosition: 'center', backgroundSize: 'cover', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column'
                }}>
                    <span className='headersignup'>
                        {Strings.registration} <div className='borderstyle'></div> </span>
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '5vmin' }}>
                        <Col className='userselection_shadowdiv' xxl={4} md={7} xl={5} lg={5} sm={8} xs={12}>
                            <span className='btnnext'>
                                Privatnutzer
                            </span>
                            <div style={{ marginTop: 20 }}>
                                <span className='main-header'>Deine Vorteile:</span>
                                <ul>
                                    <li className='subtext'><span>{'Eine vielfältige Auswahl an Camper Vans für jeden Reisezweck, von Offroad-Abenteuern bis zum Strandurlaub.'}</span></li>
                                    <li className='subtext'><span>{'Detaillierte Informationen zu allen Anbietern und Fahrzeugen'}</span></li>
                                    <li className='subtext'><span>{`Kontinuierlich aktualisierte Auswahl an Campervans`}</span></li>
                                </ul>
                            </div>
                            <div style={{ position: 'absolute', bottom: '5vmin', cursor: 'pointer' }} onClick={() => { gotoRegistration('private-user') }}>
                                <span className='subtext' style={{ textDecoration: 'underline' }}>Als Privatnutzer fortfahren</span>
                            </div>
                        </Col>
                        <Col className='userselection_shadowdiv' xxl={4} md={7} xl={5} lg={5} sm={8} xs={12}>
                            <span className='btnnext' >
                                Unternehmen
                            </span>
                            <div style={{ marginTop: 20 }}>
                                <span className='main-header'>Eure Vorteile:</span>
                                <ul>
                                    <li className='subtext'>  <span>{'Attraktive und Konsumentengerechte Präsentation Ihres Unternehmens'}</span></li>
                                    <li className='subtext'> <span>{`Wir bewerben die bei uns gelisteteten Unternehmen regelmäßig auf Social-Media`}</span></li>
                                    <li className='subtext'><span>{`Grundlage zur Vermarktung Ihrer Fahrzeuge auf unserer Plattform`}</span></li>
                                </ul>
                            </div>
                            <div style={{ position: 'absolute', bottom: '5vmin', cursor: 'pointer' }} onClick={() => { gotoRegistration('company') }}>
                                <span className='subtext' style={{ textDecoration: 'underline' }}>Als Unternehmen fortfahren</span>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default UserSelectionPage;