import React, { useState, useEffect, useContext } from 'react';
import '../../app.css';
import './AdPreviewPage.css'
import { Button, Col, Row, } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill'
import 'quill/dist/quill.snow.css'
import { Strings, URLS, getCookieValue, impressumLink, newtaburl, termsandconditions } from '../../utilities/constants';
import { DataContext } from '../../utilities/DataContext';
import { useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import { IoIosSend } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { IoShareSocialSharp } from "react-icons/io5";
import Footer from '../../Components/Footer/Footer';
import { ClipLoader } from "react-spinners";
import { css } from '@emotion/react';
import ImageModal from '../../Components/ImageDetail/ImageDetail';
import { Link, Element, Events, scrollSpy } from 'react-scroll';
import AlertComponent from "../../Components/AlertComponent";
import TopLabelInput from "../../Components/TpLabelInput/TopLabelInput";
import { FiAlertTriangle } from "react-icons/fi";
import SuccessAlert from "../../Components/SuccessAlert";
import { trackPageView } from '../../hooks/analytics';




const AdPreviewPage = ({ closeLoginModel }) => {
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState('');
    const [isTitleReady, setIsTitleReady] = useState(false);
    const [activeSection, setActiveSection] = useState('section1');
    const [requestMsg, setRequestMsg] = useState('')
    const [loading, setLoading] = useState(false);
    let { adId } = useParams();
    const { userData, token, addDetails, setAddDetails, isLoggedIn, setMessageCount, setChatData } = useContext(DataContext);
    const [expandedImageTitle, setExpandedImageTitle] = useState(null);
    const [equipment, setEquipment] = useState([])
    const [firstImages, setFirstImages] = useState([])
    const [expandedData, setExpandedData] = useState(null)
    const [expandedGallery, setExpandedGallery] = useState([])
    const [allGallery, setAllGallery] = useState([])
    const [isExpanded, setIsExpanded] = useState(false);
    const [adStatus, setAdStatus] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [brand, setBrand] = useState('');
    const [currentImage, setCurrentImage] = useState(null)
    const [formattedPrice, setFormattedPrice] = useState('')
    const [error, setError] = useState('')
    const [showalert, setShowAlert] = useState(false)
    const [companyInfo, setCompanyInfo] = useState({})
    const [showSuccessalert, setShowSuccessAlert] = useState(false)
    const [successMessage, setSuccessMsg] = useState('')
    const [showLogin, setShowLogin] = useState(false)
    const [form1Errors, setForm1Errors] = useState({
        email: "",
        message: ''
    });

    useEffect(() => {
        window.dataLayer.push({
            'event': 'gtag.init',
            'gtag.config': {
                trackingId: 'G-4CG75M28S5',
                send_page_view: false
            }
        });
    }, [window.location.pathname])

    useEffect(() => {
        let id = adId !== undefined ? adId : addDetails.vehicle_ad_overview_data.id
        console.log('adId', id);

        const fetchData = async () => {
            try {
                await callAddDetailApi(id); // Wait for the API call to complete before proceeding
            } catch (error) {
                console.error('Error fetching ad details:', error);
            }
        };

        fetchData(); // Trigger the async function


        Events.scrollEvent.register('begin', function (to, element) {
            console.log('begin', arguments);
        });

        Events.scrollEvent.register('end', function (to, element) {
            console.log('end', arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);


    // useEffect(() => {

    //     console.log("After tracking page view, dataLayer: ", window.dataLayer);
    // }, [pageTitle])


    const openModal = (image) => {
        console.log('image', image)
        setIsOpen(true);
        setCurrentImage(image)
    };
    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const technicalData = !isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_basic_technical_data) ? addDetails.vehicle_basic_technical_data : {};

    const dataKeys = [
        { label: 'Typ', key: 'type' },
        { label: 'Schadstoffklasse', key: 'emission_class' },
        { label: 'Hersteller', key: 'manufacturer' },
        { label: 'Umweltplakette', key: 'environmental_sticker' },
        { label: 'Modell', key: 'model' },
        { label: 'Max. Gesamtgewicht', key: 'vehicle_total_weight_kg', unit: 'kg' },
        { label: 'Anzahl der Sitze', key: 'no_of_seats' },
        { label: 'Klimatisierung', key: 'air_conditioning' },
        { label: 'Fahrzeugnummer', key: 'vehicle_number' },
        { label: 'Einparkhilfe', key: 'parking_aid' },
        { label: 'Verfügbarkeit', key: 'availability' },
        { label: 'Farbe Herstellerbezeichnung', key: 'original_color' },
        { label: 'Kilometerstand', key: 'mileage_km', unit: 'Km' },
        { label: 'Farbe', key: 'color' },
        { label: 'Leistung', key: 'power_hp' },
        { label: 'Lange', key: 'length_cm', unit: 'cm' },
        { label: 'Kraftstoffart', key: 'fuel_type' },
        { label: 'Breite', key: 'width_cm', unit: 'cm' },
        { label: 'Getriebe', key: 'gearbox' },
        { label: 'Höhe', key: 'height_cm', unit: 'cm' },
        { label: 'Baujahr', key: 'year_of_manufacture' },
        { label: 'Start/Stopp-Automatik', key: 'automatic_start_or_stop' },
        { label: 'Partikelfilter', key: 'particle_filter' },
        { label: 'Kraftstoffverbrauch (innerorts)', key: 'fuel_consumption_urban_l_per_100km', unit: 'l/100 km' },
        { label: 'Kraftstoffverbrauch (komb.)', key: 'fuel_consumption_combined_l_per_100km', unit: 'l/100 km' },
        { label: 'CO2-Emissionen (komb.)', key: 'co2_emissions_combined_g_per_km', unit: 'g/km' },
        { label: 'Kraftstoffverbrauch (außerorts)', key: 'fuel_consumption_extra_urban_l_per_100km', unit: 'l/100 km' }
    ];
    const filteredData = !isEmptyObject(technicalData) ? dataKeys.filter(({ key }) => technicalData[key] !== '' && technicalData[key] !== null) : [];

    const gotoReg = () => {
        navigate('/registrationtypeselection')
    }

    // Function to group data into pairs
    const groupDataInPairs = (data) => {
        const pairs = [];
        for (let i = 0; i < data.length; i += 2) {
            pairs.push(data.slice(i, i + 2));
        }
        return pairs;
    };

    const dataPairs = groupDataInPairs(filteredData);





    const formatCompanyName = (name) => {
        return name
            .toLowerCase()                          // Convert to lowercase
            .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
            .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
    };

    const openNewTab = (url) => {
        window.open(url, "_blank");
    };

    const gotoBusinessPage = () => {
        if (addDetails.vehicle_ad_overview_data.user.user_type == 'private-user') {
            return
        } else {
            if (companyInfo.businesspage_status === "inprogress") {
                return
            } else {
                const formattedCompanyName = formatCompanyName(companyInfo.company_name);
                // navigate(`/businesspage/${formattedCompanyName}`, { state: { id: companyInfo.id } })

                const baseUrl = newtaburl
                const componentPage = `businesspage/${formattedCompanyName}`;
                const url = baseUrl + componentPage;
                openNewTab(url)
            }
        }
    }

    const handleDescChange = (content) => {
        console.log('content', content)
        setRequestMsg(content)
    }

    const handleInputChange = (value) => {
        setUserEmail(value)
    }

    const spans = equipment.map((row, index) => (
        <div key={index}>
            <span className='normaltextvalue' style={{ marginRight: 20 }} key={index}>{row}</span>
        </div>
    ));

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleClick = (title) => {
        setExpandedImageTitle(expandedImageTitle === title ? null : title);
        if (!(isEmptyObject(addDetails.vehicle_ad_gallery_category_descriptions_data))) {
            const filteredObject = addDetails.vehicle_ad_gallery_category_descriptions_data.vehicle_feature_category_descriptions.find(
                (item) => item.vehicle_feature_category === title
            );
            setExpandedData(filteredObject)
        }
        const filteredImages = addDetails.vehicle_ad_gallery_data.vehicle_feature_categories.find(
            (item) => item.vehicle_feature_category === title
        );
        setExpandedGallery(filteredImages ? filteredImages.vehicle_category_images : []);
    };

    const formatCurrency = (value, currency) => {
        let locale = 'de-DE'; // German locale for both EUR and CHF
        let formattedValue = new Intl.NumberFormat(locale, {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);

        return `${formattedValue} ${currency.toUpperCase()}`;

    };

    const getChatList = async () => {
        const url = `${URLS.BASE_URL}${URLS.CHAT_LIST}`;
        await axios.get(url, { headers: { Authorization: token } })
            .then((response) => {
                setLoading(false);
                if (response?.data.status === 1) {
                    if (response?.data.data.length > 0) {
                        setChatData(response?.data.data);
                    }
                } else {
                    console.log('response', response);
                }
            })
            .catch(function (error) {
                console.log('error', error);
                if (error.response) {
                    // Handle error response
                }
            });
    };


    const callAddDetailApi = async (addId) => {
        setLoading(true)
        try {
            const url = `${URLS.BASE_URL}${URLS.FULL_ADD_DETAILS}${addId}`;
            const response = await axios.get(url);
            setLoading(false);
            if (response?.data.status === 1) {
                console.log('responseaddetaikls', response)
                setAddDetails(response.data)
                let pagetitle = ''
                if (!isEmptyObject(response.data.vehicle_ad_overview_data)) {
                    if (!isEmptyObject(response.data.vehicle_basic_technical_data) && (response.data.vehicle_basic_technical_data.type !== '')) {
                        if (response.data.vehicle_ad_overview_data.user.user_type === 'private-user') {
                            pagetitle = `${response.data.vehicle_ad_overview_data.vehicle_name} als ${response.data.vehicle_basic_technical_data.type} in ${response.data.vehicle_ad_overview_data.private_user_profile_information.city != null ? response.data.vehicle_ad_overview_data.private_user_profile_information.city : ''}`
                            setPageTitle(pagetitle);
                        } else {
                            pagetitle = `${response.data.vehicle_ad_overview_data.vehicle_name} als ${response.data.vehicle_basic_technical_data.type} in ${response.data.vehicle_ad_overview_data.company_information.city}`
                            setPageTitle(pagetitle);
                        }
                    } else {
                        if (response.data.vehicle_ad_overview_data.user.user_type === 'private-user') {
                            pagetitle = response.data.vehicle_ad_overview_data.private_user_profile_information.city !== null ? `${response.data.vehicle_ad_overview_data.vehicle_name} in ${response.data.vehicle_ad_overview_data.private_user_profile_information.city}` : `${response.data.vehicle_ad_overview_data.vehicle_name}`
                            setPageTitle(pagetitle);
                        } else {
                            pagetitle = `${response.data.vehicle_ad_overview_data.vehicle_name} in ${response.data.vehicle_ad_overview_data.company_information.city}`
                            setPageTitle(pagetitle);
                        }
                    }
                }


                document.title = pagetitle;

                console.log("Before tracking page view, dataLayer: ", window.dataLayer);
                const consentCookie = getCookieValue('cookie_consent_level');
                if (consentCookie) {
                    const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
                    console.log('consentStatus', consentStatus.tracking);
                    if (consentStatus.tracking) {
                        trackPageView(window.location.pathname, pagetitle);
                    }
                }

                let brandtype = ''

                if (!isEmptyObject(response.data.vehicle_ad_overview_data)) {
                    if (response.data.vehicle_ad_overview_data.company_information && !isEmptyObject(response.data.vehicle_ad_overview_data.company_information)) {
                        setCompanyInfo(response.data.vehicle_ad_overview_data.company_information)
                    } else if (response.data.vehicle_ad_overview_data.private_user_profile_information && !isEmptyObject(response.data.vehicle_ad_overview_data.private_user_profile_information)) {
                        setCompanyInfo(response.data.vehicle_ad_overview_data.private_user_profile_information)
                    }
                    setAdStatus(response.data.vehicle_ad_overview_data.publish_status)
                    const currency = response.data.vehicle_ad_overview_data.currency
                    const price = response.data.vehicle_ad_overview_data.price
                    if (currency !== '' && price !== '') {
                        const formatedprice = formatCurrency(price, currency)
                        console.log('formatCurrency', formatedprice)
                        setFormattedPrice(formatedprice)
                    }
                    if (!isEmptyObject(response.data.vehicle_basic_technical_data)) {
                        if (response.data.vehicle_ad_overview_data.conversion_brand !== '' && response.data.vehicle_basic_technical_data.manufacturer !== '') {
                            brandtype = `${response.data.vehicle_basic_technical_data.manufacturer}/${response.data.vehicle_ad_overview_data.conversion_brand}`
                        } else if (response.data.vehicle_ad_overview_data.conversion_brand !== '' && response.data.vehicle_basic_technical_data.manufacturer == '') {
                            brandtype = `${response.data.vehicle_ad_overview_data.conversion_brand}`
                        } else if (response.data.vehicle_ad_overview_data.conversion_brand == '' && response.data.vehicle_basic_technical_data.manufacturer !== '') {
                            brandtype = `${response.data.vehicle_basic_technical_data.manufacturer}`
                        }
                    } else {
                        brandtype = `${response.data.vehicle_ad_overview_data.conversion_brand}`
                    }
                    setBrand(brandtype)
                }

                const data = !isEmptyObject(response.data.vehicle_ad_gallery_data) ? response.data.vehicle_ad_gallery_data.vehicle_feature_categories : null
                if (data !== null) {
                    const allImages = data.flatMap(
                        (category) => category.vehicle_category_images
                    );
                    console.log('filteredImages', allImages)
                    setAllGallery(allImages.length > 0 ? allImages : []);

                    const imagesArray = extractFirstImages(data);
                    setFirstImages(imagesArray)
                    let equipmentArray = !isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_basic_technical_data) ? addDetails.vehicle_basic_technical_data.equipments : []
                    setEquipment(equipmentArray)
                    console.log('imagesArray', imagesArray)
                }

            } else {
                console.log('response', response);
                setShowAlert(true)
                setError(response.data.message)
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
            if (error.response) {
                setShowAlert(true)
                setError(error.response ? error.response.data.message : '')
            }
        }
        // await axios.get(url)
        //     .then((response) => {
        //         setLoading(false)
        //         if (response?.data.status === 1) {
        //             console.log('responseaddetaikls', response)
        //             setAddDetails(response.data)
        //             let pagetitle = ''
        //             if (!isEmptyObject(response.data.vehicle_ad_overview_data)) {
        //                 if (!isEmptyObject(response.data.vehicle_basic_technical_data) && (response.data.vehicle_basic_technical_data.type !== '')) {
        //                     if (response.data.vehicle_ad_overview_data.user.user_type === 'private-user') {
        //                         pagetitle = `${response.data.vehicle_ad_overview_data.vehicle_name} als ${response.data.vehicle_basic_technical_data.type} in ${response.data.vehicle_ad_overview_data.private_user_profile_information.city != null ? response.data.vehicle_ad_overview_data.private_user_profile_information.city : ''}`
        //                         setPageTitle(pagetitle);
        //                     } else {
        //                         pagetitle = `${response.data.vehicle_ad_overview_data.vehicle_name} als ${response.data.vehicle_basic_technical_data.type} in ${response.data.vehicle_ad_overview_data.company_information.city}`
        //                         setPageTitle(pagetitle);
        //                     }
        //                 } else {
        //                     if (response.data.vehicle_ad_overview_data.user.user_type === 'private-user') {
        //                         pagetitle = response.data.vehicle_ad_overview_data.private_user_profile_information.city !== null ? `${response.data.vehicle_ad_overview_data.vehicle_name} in ${response.data.vehicle_ad_overview_data.private_user_profile_information.city}` : `${response.data.vehicle_ad_overview_data.vehicle_name}`
        //                         setPageTitle(pagetitle);
        //                     } else {
        //                         pagetitle = `${response.data.vehicle_ad_overview_data.vehicle_name} in ${response.data.vehicle_ad_overview_data.company_information.city}`
        //                         setPageTitle(pagetitle);
        //                     }
        //                 }
        //             }


        //             let brandtype = ''

        //             if (!isEmptyObject(response.data.vehicle_ad_overview_data)) {
        //                 if (response.data.vehicle_ad_overview_data.company_information && !isEmptyObject(response.data.vehicle_ad_overview_data.company_information)) {
        //                     setCompanyInfo(response.data.vehicle_ad_overview_data.company_information)
        //                 } else if (response.data.vehicle_ad_overview_data.private_user_profile_information && !isEmptyObject(response.data.vehicle_ad_overview_data.private_user_profile_information)) {
        //                     setCompanyInfo(response.data.vehicle_ad_overview_data.private_user_profile_information)
        //                 }
        //                 setAdStatus(response.data.vehicle_ad_overview_data.publish_status)
        //                 const currency = response.data.vehicle_ad_overview_data.currency
        //                 const price = response.data.vehicle_ad_overview_data.price
        //                 if (currency !== '' && price !== '') {
        //                     const formatedprice = formatCurrency(price, currency)
        //                     console.log('formatCurrency', formatedprice)
        //                     setFormattedPrice(formatedprice)
        //                 }
        //                 if (!isEmptyObject(response.data.vehicle_basic_technical_data)) {
        //                     if (response.data.vehicle_ad_overview_data.conversion_brand !== '' && response.data.vehicle_basic_technical_data.manufacturer !== '') {
        //                         brandtype = `${response.data.vehicle_basic_technical_data.manufacturer}/${response.data.vehicle_ad_overview_data.conversion_brand}`
        //                     } else if (response.data.vehicle_ad_overview_data.conversion_brand !== '' && response.data.vehicle_basic_technical_data.manufacturer == '') {
        //                         brandtype = `${response.data.vehicle_ad_overview_data.conversion_brand}`
        //                     } else if (response.data.vehicle_ad_overview_data.conversion_brand == '' && response.data.vehicle_basic_technical_data.manufacturer !== '') {
        //                         brandtype = `${response.data.vehicle_basic_technical_data.manufacturer}`
        //                     }
        //                 } else {
        //                     brandtype = `${response.data.vehicle_ad_overview_data.conversion_brand}`
        //                 }
        //                 setBrand(brandtype)
        //             }

        //             const data = !isEmptyObject(response.data.vehicle_ad_gallery_data) ? response.data.vehicle_ad_gallery_data.vehicle_feature_categories : null
        //             if (data !== null) {
        //                 const allImages = data.flatMap(
        //                     (category) => category.vehicle_category_images
        //                 );
        //                 console.log('filteredImages', allImages)
        //                 setAllGallery(allImages.length > 0 ? allImages : []);

        //                 const imagesArray = extractFirstImages(data);
        //                 setFirstImages(imagesArray)
        //                 let equipmentArray = !isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_basic_technical_data) ? addDetails.vehicle_basic_technical_data.equipments : []
        //                 setEquipment(equipmentArray)
        //                 console.log('imagesArray', imagesArray)
        //             }

        //         } else {
        //             console.log('response', response);
        //             setShowAlert(true)
        //             setError(response.data.message)
        //         }
        //     })
        //     .catch(function (error) {
        //         setLoading(false)
        //         console.log('error', error);
        //         if (error.response) {
        //             setShowAlert(true)
        //             setError(error.response ? error.response.data.message : '')
        //         }

        //     });
    }

    const formatMilageNumber = (value) => {
        return new Intl.NumberFormat('de-DE').format(value);
    }


    const extractFirstImages = (data) => {
        console.log('imageData', data)
        return data.map(category => {
            const firstImage = category.vehicle_category_images ? category.vehicle_category_images[0]?.vehicle_category_image_2k : null;
            const firstImage4k = category.vehicle_category_images ? category.vehicle_category_images[0]?.vehicle_category_image_4k : null;
            const firstImageId = category.vehicle_category_images ? category.vehicle_category_images[0]?.id : null;
            return firstImage ? { category: category.vehicle_feature_category, vehicle_category_image: firstImage, id: firstImageId, vehicle_category_image_4k: firstImage4k } : null;
        }).filter(item => item !== null);
    };

    const validateData = () => {
        let errors = {}
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // if (userEmail === undefined || userEmail === "") {
        //     errors.email = Strings.email_err
        // } else {
        //     if (!emailRegex.test(userEmail)) {
        //         errors.email = Strings.invalid_email
        //     }
        // }
        // console.log('requestMsg', requestMsg)
        if (requestMsg === undefined || requestMsg === "" || requestMsg === "<p><br></p>") {
            errors.message = "Bitte füllen Sie dieses Pflichtfeld aus."
        }

        return errors
    }

    const sendMessage = () => {
        const errors = validateData();
        setForm1Errors(errors);
        if (Object.keys(errors).length === 0) {
            sendEmail()
        }

    }

    const sendEmail = async () => {
        console.log('email >> ', userEmail)
        console.log('requestMsg >>', requestMsg)
        setLoading(true)
        // const url = `${URLS.BASE_URL}${URLS.AD_MESSAGE}?vehicle_ad_id=${addDetails.vehicle_ad_overview_data.id}&message=${requestMsg}&seller_email=${addDetails.vehicle_ad_overview_data.company_information.company_email}&buyer_email=${userEmail}`;
        const url = `${URLS.BASE_URL}${URLS.CHAT_MESSAGE_CREATE}`;
        const form = new FormData();
        form.append('vehicle_ad_overview', addDetails.vehicle_ad_overview_data.id)
        form.append('message', requestMsg)
        form.append('recipient_user', addDetails.vehicle_ad_overview_data.user.id)
        form.append('sender_user', userData.id)
        form.append('chat', '')
        const formDataObject = {};
        form.forEach((value, key) => {
            formDataObject[key] = value;
        });


        console.log('form >>', formDataObject)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        await axios.post(url, form, { headers: { Authorization: token } })
            .then((response) => {
                setLoading(false)
                console.log('response', response);
                if (response?.data.status === 1) {
                    setRequestMsg('')
                    setUserEmail('')
                    setShowSuccessAlert(true)
                    setSuccessMsg('Mail erfolgreich gesendet')
                    getChatList()
                    callUnreadMessageCount()
                } else {
                    console.log('response', response);
                    setShowAlert(true)
                    setError(response.data.message)
                    setSuccessMsg('')
                    setShowSuccessAlert(false)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }


    const callUnreadMessageCount = async () => {
        const url = `${URLS.BASE_URL}${URLS.UNREAD_MESSAGE_COUNT}`;
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                console.log(response)
                setLoading(false)
                if (response?.data.status === 1) {
                    setMessageCount(response.data.data.total_unread_count)
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    function CompanyAddress() {
        return (
            <div>
                {companyInfo.street && companyInfo.street_number &&
                    <span className='address_text'>{companyInfo.street} {companyInfo.street_number}</span>
                }
                {(companyInfo.street || companyInfo.street_number) &&
                    companyInfo.zip_code &&
                    <span className='address_text'>, {companyInfo.zip_code}
                    </span>
                }
                {(companyInfo.street || companyInfo.street_number || companyInfo.zip_code) &&
                    companyInfo.city &&
                    <span className='address_text'> {companyInfo.city}</span>
                }
                {(companyInfo.street || companyInfo.street_number || companyInfo.zip_code || companyInfo.city) &&
                    companyInfo.country &&
                    <span className='address_text'>, {companyInfo.country}</span>
                }
            </div>
        );
    }

    const generateRows = () => {
        const rows = [];
        let index = 0;
        // Function to split array into chunks of specified size
        const chunkArray = (array, size) => {
            const result = [];
            for (let i = 0; i < array.length; i += size) {
                result.push(array.slice(i, i + size));
            }
            return result;
        };

        const imageChunks = chunkArray(expandedGallery, 5);
        return (
            <div className='add_galleryvw'>
                {imageChunks.map((chunk, chunkIndex) => (
                    <div className='gallery_row'>
                        <Row className='rowimage'>
                            {chunkIndex % 2 === 0 ? (
                                // Layout for the first 5 images
                                <>
                                    <Col className='image_section' md={8}>
                                        <Row className='rowimage'>
                                            {chunk.slice(0, 2).map((image, index) => (
                                                image.vehicle_category_image_2k && (
                                                    <Col md={index % 2 === 0 ? 5 : 7} className="pic_items" key={`pic_item_${chunkIndex}_${index}`}>
                                                        <div
                                                            className="pic"
                                                            style={{ backgroundImage: `url(${image.vehicle_category_image_2k})` }}
                                                            onClick={() => openModal(image)}
                                                        />
                                                    </Col>
                                                )
                                            ))}
                                        </Row>
                                        <Row className='rowimage'>
                                            {chunk.slice(2, 4).map((image, index) => (
                                                image.vehicle_category_image_2k && (
                                                    <Col md={index % 2 === 0 ? 7 : 5} className="pic_items" key={`pic_item_${chunkIndex}_${index + 2}`}>
                                                        <div
                                                            className="pic"
                                                            style={{ backgroundImage: `url(${image.vehicle_category_image_2k})` }}
                                                            onClick={() => openModal(image)}
                                                        />
                                                    </Col>
                                                )))}
                                        </Row>
                                    </Col>
                                    <Col className='right_image_section' md={4}>
                                        <div className='right_column col-12'>
                                            {chunk.length > 4 && chunk[4] && chunk[4].vehicle_category_image_2k && (
                                                <div className='right_column col-12'>
                                                    <div className="pic" style={{ backgroundImage: `url(${chunk[4].vehicle_category_image_2k})` }}
                                                        onClick={() => openModal(chunk[4])}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </>
                            ) : (
                                // Revised layout for the remaining images
                                <>
                                    <Col className='right_image_section' md={4}>
                                        <div className='right_column col-12'>
                                            {chunk[0] && chunk[0].vehicle_category_image_2k && (
                                                <div className='right_column col-12'>
                                                    <div className="pic" style={{ backgroundImage: `url(${chunk[0].vehicle_category_image_2k})` }}
                                                        onClick={() => openModal(chunk[0])}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col className='image_section' md={8}>
                                        <Row className='rowimage'>
                                            {chunk.slice(1, 3).map((image, index) => (
                                                image.vehicle_category_image_2k && (
                                                    <Col md={index % 2 === 0 ? 5 : 7} className="pic_items" key={`pic_item_${chunkIndex}_${index}`}>
                                                        <div
                                                            className="pic"
                                                            style={{ backgroundImage: `url(${image.vehicle_category_image_2k})` }}
                                                            onClick={() => openModal(image)}
                                                        />
                                                    </Col>
                                                )
                                            ))}
                                            {chunk.slice(3, 5).map((image, index) => (
                                                image.vehicle_category_image_2k && (
                                                    <Col md={index % 2 === 0 ? 7 : 5} className="pic_items" key={`pic_item_${chunkIndex}_${index + 2}`}>
                                                        <div
                                                            className="pic"
                                                            style={{ backgroundImage: `url(${image.vehicle_category_image_2k})` }}
                                                            onClick={() => openModal(image)}
                                                        />
                                                    </Col>
                                                )))}
                                        </Row>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </div>))
                }
            </div >
        )
    };

    return (
        <>
            {/* <Helmet>
                <title>{pagetitle}</title>
                <meta name="description" content={pagetitle} />
                <meta property="og:title" content={pagetitle} />
                <meta property="og:description" content={pagetitle} />
            </Helmet> */}
            <Header loginClicked={showLogin} isClose={() => { setShowLogin(false) }} />
            {loading && (
                <div className="loader">
                    <ClipLoader
                        color={"#123abc"}
                        loading={loading}
                        css={override}
                        size={50}
                    />
                </div>
            )}
            {(adStatus === 'published' && (userData.id === addDetails.vehicle_ad_overview_data.user.id)) ? <div style={{ backgroundColor: 'green', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 900, position: 'absolute', marginTop: 75 }}>
                <span className='redtext' >{'Deine Anzeige ist veröffentlicht.'}</span>
            </div> : ((adStatus === 'unpublished' || adStatus == 'inprogress') && (userData.id === addDetails.vehicle_ad_overview_data.user.id)) ? <div style={{ backgroundColor: 'red', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 900, position: 'absolute', marginTop: 75 }}>
                <span className='redtext' >{'Deine Anzeige ist noch nicht veröffentlicht.'}</span>
            </div> : null}

            <div className="full-width-container" style={{ height: "100vh" }}>
                <div style={{ backgroundColor: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "90vh", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: !isEmptyObject(addDetails) ? !isEmptyObject(addDetails.vehicle_ad_overview_data) ? (addDetails.vehicle_ad_overview_data.hero_image_4k !== null) ? `url(${addDetails.vehicle_ad_overview_data.hero_image_4k})` : null : null : null }} />
                <nav className="navbar_sections">
                    {/* <Row> */}
                    <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Col xxl={1} xl={2} lg={2} md={2} xs={3} style={{ marginBottom: 10 }} className={activeSection === 'section1' ? 'active-section' : 'inactive'}>
                            <li>
                                <Link to="section1" smooth={true} duration={500} spy={true} offset={-70} onClick={() => setActiveSection('section1')}>
                                    Überblick
                                </Link>
                            </li>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} xs={5} style={{ marginBottom: 10 }} className={activeSection === 'section2' ? 'active-section' : 'inactive'}>
                            <li >
                                <Link to="section2" smooth={true} duration={500} spy={true} offset={-50} onClick={() => setActiveSection('section2')}>
                                    Fahrzeug im Detail
                                </Link>
                            </li>
                        </Col>
                        <Col xxl={1} xl={2} lg={2} md={2} xs={4} style={{ marginBottom: 10 }} className={activeSection === 'section3' ? 'active-section' : 'inactive'}>
                            <li >
                                <Link to="section3" smooth={true} duration={500} spy={true} offset={-70} onClick={() => setActiveSection('section3')}>
                                    Galerie
                                </Link>
                            </li>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} xs={4} style={{ marginBottom: 10 }} className={activeSection === 'section4' ? 'active-section' : 'inactive'}>
                            <li >
                                <Link to="section4" smooth={true} duration={500} spy={true} offset={-70} onClick={() => setActiveSection('section4')}>
                                    Technische Daten
                                </Link>
                            </li>
                        </Col>
                        <Col xxl={1} xl={2} lg={2} md={2} xs={4} style={{ marginBottom: 10 }} className={activeSection === 'section5' ? 'active-section' : 'inactive'}>
                            <li >
                                <Link to="section5" smooth={true} duration={500} spy={true} offset={-50} onClick={() => setActiveSection('section5')}>
                                    Basisfahrzeug
                                </Link>
                            </li>
                        </Col>
                        <Col xxl={1} xl={2} lg={2} md={2} xs={4} style={{ marginBottom: 10 }} className={activeSection === 'section6' ? 'active-section' : 'inactive'}>
                            <li >
                                <Link to="section6" smooth={true} duration={500} spy={true} offset={-70} onClick={() => setActiveSection('section6')}>
                                    Anfrage
                                </Link>
                            </li>
                        </Col>
                    </Row>
                    {/* </Row> */}

                </nav>
                <div className='add_content'>
                    <Element name="section1">
                        <Row style={{ margin: 30 }}>
                            <Col md={7} xxl={8} xl={7}>
                                <div>
                                    <Row >
                                        <Col md={6} xxl={8} xl={6} lg={8} sm={6} xs={6}>
                                            <h3 className='vehiclename'>{(!isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_ad_overview_data)) ? addDetails.vehicle_ad_overview_data.vehicle_name !== '' ? addDetails.vehicle_ad_overview_data.vehicle_name : '' : ''}</h3>
                                        </Col>
                                        <Col md={6} xxl={4} xl={6} lg={4} sm={6} xs={6}>
                                            <h4 className='vehiclename'> {!isEmptyObject(addDetails) ? (addDetails.vehicle_ad_overview_data.price && addDetails.vehicle_ad_overview_data.currency) ? `${formattedPrice}` : '' : ''}</h4>
                                        </Col>
                                        {/* <Col md={7} xxl={3} xl={5}>
                                            <h6 className='vehiclename'>{(!isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_ad_overview_data)) ? addDetails.vehicle_ad_overview_data.conversion_brand !== '' ? addDetails.vehicle_ad_overview_data.conversion_brand : '' : ''}</h6>
                                        </Col> */}
                                    </Row>
                                    <h6 className='brand'>{brand}</h6>
                                    <p className='addvehicle_description' style={{ marginTop: '2vmin' }}>
                                        <span dangerouslySetInnerHTML={{ __html: !isEmptyObject(addDetails) ? addDetails.vehicle_ad_overview_data.vehicle_description ? addDetails.vehicle_ad_overview_data.vehicle_description : '' : '' }} />
                                    </p>
                                </div>
                            </Col>
                            <Col md={10} xxl={4} xl={5} lg={5} style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                {!isEmptyObject(addDetails) ? (addDetails.vehicle_ad_overview_data.highlight_1 || addDetails.vehicle_ad_overview_data.highlight_2 || addDetails.vehicle_ad_overview_data.highlight_3) ?
                                    <div className='content-inner'>
                                        <span className="hilightstext">{'Highlights'}</span>
                                        <ul className="custom-list" style={{ fontSize: '2.5vh' }} >
                                            {addDetails.vehicle_ad_overview_data.highlight_1 && <li className='li' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/check.png"})`, backgroundPosition: 'left center', display: 'flex', alignItems: 'center', paddingBottom: 20, backgroundSize: 'auto' }}>  <span style={{ marginLeft: '20px', marginTop: 12 }}>{addDetails.vehicle_ad_overview_data.highlight_1}</span></li>}
                                            {addDetails.vehicle_ad_overview_data.highlight_2 && <li className='li' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/check.png"})`, backgroundPosition: 'left center', display: 'flex', alignItems: 'center', paddingBottom: 20, backgroundSize: 'auto' }}> <span style={{ marginLeft: '20px', marginTop: 10 }}>{addDetails.vehicle_ad_overview_data.highlight_2}</span></li>}
                                            {addDetails.vehicle_ad_overview_data.highlight_3 && <li className='li' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/check.png"})`, backgroundPosition: 'left center', display: 'flex', alignItems: 'center', paddingBottom: 20, backgroundSize: 'auto' }}><span style={{ marginLeft: '20px', marginTop: 10 }}>{addDetails.vehicle_ad_overview_data.highlight_3}</span></li>}
                                        </ul>
                                    </div> : null : null
                                }


                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                    <Link className="enquire_btn" to="section6" smooth={true} duration={500} spy={true} offset={-70} onClick={() => setActiveSection('section6')}>
                                        <IoIosSend style={{ marginLeft: 5 }} /> Hier Anfragen
                                    </Link>
                                    {isLoggedIn && <button className="like_btn" > <FaRegHeart style={{ marginLeft: 5 }} /> Merken</button>}
                                    {/* <button className="enquire_btn" onClick={() => setActiveSection('section6')}> <IoIosSend style={{ marginLeft: 5 }} /> Hier Anfragen</button> */}

                                </div>

                                {!isEmptyObject(companyInfo) &&
                                    <div className='add_contact_div'>
                                        <Row>
                                            <Col md={12} style={{ display: 'flex', flexDirection: 'column' }}  >
                                                <div style={{ display: 'flex' }}>
                                                    <span className="contact_header">{'Verkäufer Details'}</span>
                                                </div>
                                                {
                                                    !isEmptyObject(companyInfo) && ((companyInfo.company_logo && companyInfo.company_logo !== null) || (companyInfo.profile_image && companyInfo.profile_image !== null)) ?
                                                        <Row style={{ marginTop: 10, marginBottom: 10, justifyContent: 'center' }}>
                                                            <Col xxl={4} xl={4} lg={4} md={3} sm={3} xs={4} style={{ cursor: 'pointer' }} onClick={gotoBusinessPage}>
                                                                <img style={{ height: '100%', width: '100%', objectFit: 'contain', marginRight: 20, maxHeight: 100, maxWidth: 100 }} src={(companyInfo.company_logo && companyInfo.company_logo !== null) ? companyInfo.company_logo : companyInfo.profile_image !== null ? companyInfo.profile_image : ''} />
                                                            </Col>
                                                            <Col xxl={5} xl={5} lg={5} md={6} sm={6} xs={5} style={{ alignContent: 'center', cursor: 'pointer' }} onClick={gotoBusinessPage}>
                                                                {!isEmptyObject(companyInfo) && (companyInfo.company_name ? <span className='businessName'>{companyInfo.company_name}</span> : <span className='businessName'>{`${addDetails.vehicle_ad_overview_data.user.first_name} ${addDetails.vehicle_ad_overview_data.user.last_name}`}</span>)}
                                                                {!isEmptyObject(companyInfo) && <CompanyAddress></CompanyAddress>}
                                                            </Col>
                                                            <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} style={{ alignContent: 'center' }}>
                                                                <button className="share_btn" > <IoShareSocialSharp style={{ marginRight: 5 }} />Teilen</button>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row style={{ marginTop: 10, marginBottom: 10, justifyContent: 'space-between' }}>
                                                            <Col xxl={9} xl={7} lg={8} md={6} sm={6} xs={7} style={{ alignContent: 'center', cursor: 'pointer' }} onClick={gotoBusinessPage}>
                                                                {!isEmptyObject(companyInfo) && (companyInfo.company_name ? <span className='businessName'>{companyInfo.company_name}</span> : <span className='businessName'>{`${addDetails.vehicle_ad_overview_data.user.first_name} ${addDetails.vehicle_ad_overview_data.user.last_name}`}</span>)}
                                                                {!isEmptyObject(companyInfo) && <CompanyAddress></CompanyAddress>}
                                                            </Col>
                                                            <Col xxl={3} xl={3} lg={4} md={3} sm={3} xs={3} style={{ alignContent: 'center' }}>
                                                                <button className="share_btn" > <IoShareSocialSharp style={{ marginRight: 5 }} />Teilen</button>
                                                            </Col>
                                                        </Row>

                                                }

                                                {/* <p className='address_text'>{`84633 Ascholding, Grabenwiese 17a, Deutschland`}</p> */}
                                            </Col>
                                            {/* <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}> */}
                                            {/* <button className="share_btn" > <IoCall style={{ marginRight: 10 }} />Anrufen</button> */}

                                            {/* </Col> */}
                                        </Row>
                                    </div>
                                }

                            </Col>
                        </Row>
                        {firstImages.length > 0 &&
                            <div className='add_galleryvw'>
                                <div className='add_row_fluid'>
                                    <Row className='add_rowimage'  >
                                        {[0, 1].map((index) => (
                                            <Col md={index % 2 === 0 ? 5 : 7} key={index} className="add_pic_items">
                                                {firstImages[index] &&
                                                    <div className="add_pic" style={{
                                                        backgroundImage: firstImages.length > 0 && firstImages[index] ? `url(${firstImages[index].vehicle_category_image})` : null, backgroundPosition: "center", backgroundSize: "cover",
                                                        backgroundColor: 'gainsboro'
                                                    }}
                                                        onClick={() => openModal(firstImages[index])}
                                                    />
                                                }

                                            </Col>
                                        ))}
                                    </Row>
                                    <Row className='add_rowimage'>
                                        {[2, 3].map((index) => (
                                            <Col className='add_pic_items' md={index % 2 === 0 ? 7 : 5} key={index}>
                                                {firstImages[index] &&
                                                    <div className="add_pic" style={{
                                                        backgroundImage: firstImages.length > 0 && firstImages[index] ? `url(${firstImages[index].vehicle_category_image})` : null, backgroundSize: "cover",
                                                        backgroundColor: 'gainsboro', backgroundPosition: "center",
                                                    }}
                                                        onClick={() => openModal(firstImages[index])}
                                                    />
                                                }

                                            </Col>
                                        ))}
                                    </Row>
                                    <Row className='add_rowimage'>
                                        {[4, 5, 6].map((index) => (
                                            <Col className='add_pic_items' md={4} key={index}>
                                                {firstImages[index] &&
                                                    <div className="add_pic1" style={{
                                                        backgroundImage: firstImages.length > 0 && firstImages[index] ? `url(${firstImages[index].vehicle_category_image})` : null, backgroundSize: "cover",
                                                        backgroundColor: 'gainsboro', backgroundPosition: "center",
                                                    }}
                                                        onClick={() => openModal(firstImages[index])}
                                                    />
                                                }
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        }
                    </Element>
                    <Element name="section2">
                        {!isEmptyObject(addDetails) && addDetails.vehicle_ad_overview_data.vehicle_detail !== '' &&
                            <div className='vehicle_details_div'>
                                <h4 className='vehiclename'>Fahrzeug im Detail</h4>
                                <p className='addvehicle_description'>
                                    <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_overview_data.vehicle_detail }} />
                                </p>
                            </div>}
                    </Element>
                    <Element name="section3">
                        {!isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_ad_gallery_data) &&
                            <div style={{ margin: 10, paddingLeft: 20, paddingRight: 20 }}>
                                <h4 className='vehiclename'>Galerie</h4>
                                <div className="image-grid">
                                    {!isEmptyObject(addDetails.vehicle_ad_gallery_data) && addDetails.vehicle_ad_gallery_data.vehicle_feature_categories.map((item) => {
                                        return (
                                            <div>
                                                {
                                                    item.vehicle_category_images.length > 0 &&
                                                    <div
                                                        key={item.vehicle_feature_category}
                                                        className={`image-row ${expandedImageTitle === item.vehicle_feature_category ? 'expanded' : ''}`}
                                                        onClick={() => handleClick(item.vehicle_feature_category)}
                                                    >
                                                        {item.vehicle_category_images.length > 0 && < img src={item.vehicle_category_images[0].vehicle_category_image_2k} alt={item.vehicle_feature_category} style={{ objectFit: 'cover', height: '100%', borderRadius: 10, }} />}
                                                        {expandedImageTitle !== item.vehicle_feature_category && <div className="image-title">{item.vehicle_feature_category}</div>}
                                                    </div>
                                                }

                                            </div>
                                        )

                                    })
                                    }
                                </div>
                                <div style={{ width: '100%', display: 'flex' }}>
                                    <div className="image-details">
                                        <h2 style={{ color: 'blcak' }}>{expandedImageTitle}</h2>
                                        <span className='selectedHeader'>{expandedData ? expandedData.category_heading : ''}</span>
                                        {/* <p className='selectedDescription'>{expandedData ? expandedData.category_description : ''}</p> */}
                                        <div className="expandable-text">
                                            <p className={isExpanded ? 'expanded' : 'collapsed'} dangerouslySetInnerHTML={{ __html: expandedData ? expandedData.category_description : '' }}></p>
                                            {
                                                expandedData && expandedData.category_description != '' &&
                                                <button onClick={toggleExpand} style={{ color: 'orange', textDecoration: 'underline' }}>
                                                    {isExpanded ? 'Weniger sehen' : 'Mehr ansehen'}
                                                </button>
                                            }
                                        </div>

                                        {/* {expandedGallery.map((item) => {
                            console.log('itemgallery', item)
                            return (
                                <div
                                    key={item.id}
                                    style={{ borderRadius: 10 }}
                                >
                                    <img src={item.vehicle_category_image} style={{ objectFit: 'contain', height: 200, width: 200, borderRadius: 10, backgroundColor: 'gray' }} />
                                    <div className="image-title" />
                                </div>
                            )
                        })
                        } */}
                                        <div className='add_galleryvw' style={{ marginTop: 20 }}>
                                            <div className='gallery_row'>
                                                {generateRows()}
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        }
                    </Element>
                    <Element name="section4">
                        {!isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_ad_feature_technical_data) &&
                            <div className='techical_data_div'>
                                <h4 className='vehiclename'>Technische Daten</h4>
                                {
                                    addDetails.vehicle_ad_feature_technical_data.technical_data_description &&
                                    <p className='addvehicle_description'>
                                        <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_description }} />
                                    </p>
                                }

                                <Row>
                                    {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[3].category_description &&
                                        <Col md={6}>
                                            <span className='small_header'>Außen</span>
                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[3].category_description }} />
                                            </p>
                                        </Col>
                                    }
                                    {
                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[4].category_description &&
                                        <Col md={6}>
                                            <span className='small_header'>Wohnen</span>
                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[4].category_description }} />
                                            </p>
                                        </Col>
                                    }

                                </Row>
                                <Row>
                                    {
                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[5].category_description &&
                                        <Col md={6}>
                                            <span className='small_header'>Küche</span>
                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[5].category_description }} />
                                            </p>
                                        </Col>
                                    }
                                    {(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].category_description || addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.single_bed_specifications.length > 0 || addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.double_bed_specifications.length > 0) &&
                                        <Col md={6}>
                                            <span className='small_header'>Schlafen</span>
                                            <Row>
                                                {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.double_bed_specifications.length > 0 &&
                                                    <Col md={6}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.double_bed_specifications.length > 0 &&
                                                                <div>
                                                                    <span className='addvehicle_description' style={{ marginTop: 5 }}>{`${addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.double_bed_specifications.length} X Doppelbett`}</span>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.double_bed_specifications.map(item => (
                                                                            <span key={item.id} className='addvehicle_description' style={{ marginTop: 0 }}>
                                                                                Maße: <span>{`${item.length_cm}X${item.width_cm} cm`}</span>
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Col>}
                                                {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.single_bed_specifications.length > 0 &&
                                                    <Col md={6}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.single_bed_specifications.length > 0 &&
                                                                <div>
                                                                    <span className='addvehicle_description' style={{ marginTop: 5 }}>{`${addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.single_bed_specifications.length} X Einzelbett`}</span>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].technical_data.single_bed_specifications.map(item => (
                                                                            <span key={item.id} className='addvehicle_description' style={{ marginTop: 0 }}>
                                                                                Maße: <span>{`${item.length_cm}X${item.width_cm} cm`}</span>
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Col>}
                                            </Row>

                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[1].category_description }} />
                                            </p>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[6].category_description &&
                                        <Col md={6}>
                                            <span className='small_header'>Dach</span>
                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[6].category_description }} />
                                            </p>
                                        </Col>
                                    }
                                    {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[7].category_description &&
                                        <Col md={6}>
                                            <span className='small_header'>Keller</span>
                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[7].category_description }} />
                                            </p>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[9].category_description &&
                                        <Col md={6}>
                                            <span className='small_header'>Heizung/Klimaanlage</span>
                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[9].category_description }} />
                                            </p>
                                        </Col>
                                    }
                                    {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[8].category_description &&
                                        <Col md={6}>
                                            <span className='small_header'>Gas</span>
                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[8].category_description }} />
                                            </p>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    {/* {(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].category_description || !isEmptyObject(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data)) &&
                                        ((addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr !== null || addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr !== null) && (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr !== 0 || addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr !== 0)) &&
                                        <Col md={6}>
                                            <span className='small_header'>Wasser</span>
                                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                                {(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr !== 0) && <span className='addvehicle_description' style={{ marginTop: 5 }}>{addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr}<span>{`L Frischwassertank`}</span></span>}
                                                {(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr !== 0) && <span className='addvehicle_description' style={{ marginTop: 0 }}>{addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr}<span>{`L Abwassertank`}</span></span>}
                                            </div>
                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].category_description }} />
                                            </p>
                                        </Col>
                                    } */}
                                    {
                                        (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].category_description.trim() !== '' ||
                                            !isEmptyObject(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data)) &&
                                        (
                                            (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr !== null ||
                                                addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr !== null) &&
                                            (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr !== 0 ||
                                                addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr !== 0) ||
                                            addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].category_description.trim() !== '') &&
                                        (
                                            <Col md={6}>
                                                <span className='small_header'>Wasser</span>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr !== null &&
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr !== 0 &&
                                                        <span className='addvehicle_description' style={{ marginTop: 5 }}>
                                                            {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.fresh_water_ltr}
                                                            <span>{`L Frischwassertank`}</span>
                                                        </span>
                                                    }
                                                    {
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr !== null &&
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr !== 0 &&
                                                        <span className='addvehicle_description' style={{ marginTop: 0 }}>
                                                            {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].technical_data.water_specifications.waste_water_ltr}
                                                            <span>{`L Abwassertank`}</span>
                                                        </span>
                                                    }
                                                    {
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].category_description.trim() !== '' &&
                                                        <p className='addvehicle_description' style={{ marginTop: 10 }}>
                                                            <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[0].category_description }} />
                                                        </p>
                                                    }
                                                </div>

                                            </Col>
                                        )
                                    }

                                    {/* {(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].category_description || addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data) &&
                                        ((addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v !== 0)
                                            || (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v !== 0)
                                            || (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c !== 0)
                                            || (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w !== 0)) &&
                                        <Col md={6}>
                                            <span className='small_header'>Strom</span>
                                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                                {(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v !== 0) && <span className='addvehicle_description' style={{ marginTop: 0 }}>{addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v}<span>{`X 230V Steckdosen`}</span></span>}
                                                {(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v !== 0) && <span className='addvehicle_description' style={{ marginTop: 0 }}>{addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v}<span>{`X 12V Steckdosen`}</span></span>}
                                                {(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c !== 0) && <span className='addvehicle_description' style={{ marginTop: 0 }}>{addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c}<span>{`X USB-C Anschlüsse`}</span></span>}
                                                {(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w !== 0) && <span className='addvehicle_description' style={{ marginTop: 0 }}>{addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w}<span>{`W X Solar-Leistung`}</span></span>}
                                            </div>
                                            <p className='addvehicle_description'>
                                                <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].category_description }} />
                                            </p>
                                        </Col>
                                    } */}
                                    {
                                        (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].category_description.trim() !== '' ||
                                            !isEmptyObject(addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data)) &&
                                        (
                                            (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v !== 0) ||
                                            (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v !== 0) ||
                                            (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c !== 0) ||
                                            (addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w !== null && addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w !== 0) ||
                                            addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].category_description.trim() !== ''
                                        ) &&
                                        (
                                            <Col md={6}>
                                                <span className='small_header'>Strom</span>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v !== null &&
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v !== 0 &&
                                                        <span className='addvehicle_description' style={{ marginTop: 0 }}>
                                                            {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_230v}
                                                            <span>{`X 230V Steckdosen`}</span>
                                                        </span>
                                                    }
                                                    {
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v !== null &&
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v !== 0 &&
                                                        <span className='addvehicle_description' style={{ marginTop: 0 }}>
                                                            {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.sockets_12v}
                                                            <span>{`X 12V Steckdosen`}</span>
                                                        </span>
                                                    }
                                                    {
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c !== null &&
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c !== 0 &&
                                                        <span className='addvehicle_description' style={{ marginTop: 0 }}>
                                                            {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.connections_usb_c}
                                                            <span>{`X USB-C Anschlüsse`}</span>
                                                        </span>
                                                    }
                                                    {
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w !== null &&
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w !== 0 &&
                                                        <span className='addvehicle_description' style={{ marginTop: 0 }}>
                                                            {addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].technical_data.electricity_specifications.solar_power_w}
                                                            <span>{`W X Solar-Leistung`}</span>
                                                        </span>
                                                    }
                                                    {
                                                        addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].category_description.trim() !== '' &&
                                                        <p className='addvehicle_description' style={{ marginTop: 10 }}>
                                                            <span dangerouslySetInnerHTML={{ __html: addDetails.vehicle_ad_feature_technical_data.technical_data_category_descriptions[2].category_description }} />
                                                        </p>
                                                    }
                                                </div>

                                            </Col>
                                        )
                                    }

                                </Row>
                            </div>
                        }
                    </Element>
                    <Element name="section5">
                        {!isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_basic_technical_data) &&
                            <div className='vehicle_details_div' style={{ paddingBottom: '3vmin' }}>
                                <h4 className='vehiclename'>Basisfahrzeug</h4>
                                <span className='small_header'>Technische Daten</span>
                                {dataPairs && dataPairs.length > 0 && (
                                    dataPairs.map((pair, index) => (
                                        <Row key={index}>
                                            {pair.map(({ label, key, unit, boolean }) => (
                                                <Col md={5} key={key}>
                                                    <div className='basic_detail_div'>
                                                        <span className='preview_normaltext'>{label}</span>
                                                        <span className='normaltextvalue'>
                                                            {boolean !== undefined
                                                                ? (technicalData[key] ? 'Wahr' : 'Falsch')
                                                                : key === 'mileage_km'
                                                                    ? `${formatMilageNumber(technicalData[key])}${unit ? ` ${unit}` : ''}`
                                                                    : `${technicalData[key]}${unit ? ` ${unit}` : ''}`}
                                                        </span>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    ))
                                )}

                                {equipment.length > 0 &&
                                    <div style={{ marginTop: '5vmin', marginBottom: 20 }}>
                                        <h4 className='small_header'>Ausstattung</h4>
                                        <div>
                                            {spans}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </Element>

                    <Element name="section6">
                        <div style={{ display: 'flex', margin: '5vmin', paddingBottom: 30, flexDirection: 'column' }}>
                            <h4 className='vehiclename'>Anfrage</h4>
                            <Row>
                                <Col xxl={7} xl={7} lg={7} md={7} sm={10} xs={10} style={{ marginBottom: 20 }}>
                                    {isLoggedIn ?
                                        <div>
                                            {/* <textarea
                                                id='largeText'
                                                value={requestMsg}
                                                onChange={handleDescChange}
                                                rows="10"
                                                cols="50"
                                                placeholder='Ihre Nachricht...'
                                                style={{ width: '100%', height: '40vmin', borderRadius: 5, padding: 15, outline: 'none', borderColor: '#ccc' }}
                                            /> */}
                                            <ReactQuill
                                                theme="snow"
                                                className='admessage_quil'
                                                value={requestMsg}
                                                placeholder={'Ihre Nachricht...'}
                                                onChange={handleDescChange}
                                                modules={{
                                                    toolbar: false // Disable the toolbar
                                                }}
                                            />
                                            {(form1Errors.message) && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    <FiAlertTriangle color="red" />
                                                    <span className="error-message">{form1Errors.message}</span>
                                                </div>
                                            )}
                                        </div> :
                                        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <span className='small_header' style={{ textAlign: 'center' }}>Bitte melden Sie sich an oder registrieren Sie sich, um eine Nachricht an den Verkäufer zu senden</span>
                                            <div style={{ marginRight: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }} className='rowhome'>
                                                <div style={{ marginRight: 10 }}>
                                                    <Button variant="outline-light" style={{ width: '100%', height: 40, borderColor: 'black', color: 'black' }} className='mb-2 mb-md-0' onClick={() => { setShowLogin(true) }}>{Strings.login}</Button>
                                                </div>
                                                <div>
                                                    <Button variant="outline-light" style={{ width: '100%', height: 40, borderColor: 'black', color: 'black' }} onClick={() => gotoReg()}>{Strings.registration}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Col>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={10} xs={10}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }} >
                                        <div style={{ borderTop: '1px solid rgb(0,0,0)', padding: 20, borderBottom: '1px solid rgb(0,0,0)', marginBottom: 20 }}>
                                            <h4 className='small_header'>Verkäufer Details</h4>
                                            {
                                                !isEmptyObject(companyInfo) && ((companyInfo.company_logo && companyInfo.company_logo !== null) || (companyInfo.profile_image && companyInfo.profile_image !== null)) ?
                                                    <Row style={{ marginTop: 10, marginBottom: 10, }}>
                                                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ cursor: 'pointer' }} onClick={gotoBusinessPage}>
                                                            <img style={{ height: '100%', width: '100%', objectFit: 'contain', maxHeight: 100, maxWidth: 100 }} src={(companyInfo.company_logo && companyInfo.company_logo !== null) ? companyInfo.company_logo : companyInfo.profile_image !== null ? companyInfo.profile_image : ''} />
                                                        </Col>
                                                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8} style={{ alignContent: 'center', cursor: 'pointer' }} onClick={gotoBusinessPage}>
                                                            {!isEmptyObject(companyInfo) && (companyInfo.company_name ? <span className='businessName'>{companyInfo.company_name}</span> : <span className='businessName'>{`${addDetails.vehicle_ad_overview_data.user.first_name} ${addDetails.vehicle_ad_overview_data.user.last_name}`}</span>)}
                                                            {!isEmptyObject(companyInfo) && <CompanyAddress></CompanyAddress>}
                                                        </Col>

                                                    </Row>
                                                    :
                                                    <Row style={{ marginTop: 10, marginBottom: 10, justifyContent: 'space-between' }}>
                                                        <Col xxl={9} xl={7} lg={8} md={6} sm={6} xs={7} style={{ alignContent: 'center', cursor: 'pointer' }} onClick={gotoBusinessPage}>
                                                            {!isEmptyObject(companyInfo) && (companyInfo.company_name ? <span className='businessName'>{companyInfo.company_name}</span> : <span className='businessName'>{`${addDetails.vehicle_ad_overview_data.user.first_name} ${addDetails.vehicle_ad_overview_data.user.last_name}`}</span>)}
                                                            {!isEmptyObject(companyInfo) && <CompanyAddress></CompanyAddress>}
                                                        </Col>

                                                    </Row>

                                            }


                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', }}>
                                            <div>
                                                <p>
                                                    Mit dem Absenden ihrer Nachricht stimmen sie unseren{' '}
                                                    <a href={impressumLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                                                        AGB
                                                    </a>{' '}
                                                    und{' '}
                                                    <a href={termsandconditions} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                                                        Datenschutzerklärung
                                                    </a>{' '}
                                                    zu.
                                                </p>
                                            </div>
                                            {
                                                isLoggedIn && <button className="msg_btn" onClick={sendMessage}>Absenden</button>
                                            }

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Element>

                </div>
                {isOpen && <ImageModal open={isOpen} imageDetails={currentImage} closeModal={() => setIsOpen(false)} gallery={allGallery}></ImageModal>}
                <Footer />
            </div >
            {showalert &&
                <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
            }
            {showSuccessalert &&
                <SuccessAlert message={successMessage} onClose={() => { setShowSuccessAlert(false) }}></SuccessAlert>
            }
        </>
    );
};

export default AdPreviewPage;