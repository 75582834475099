import React, { useState, useEffect, useContext } from 'react';
import '../../app.css';
import '../AdsList/AdsList.css'
import Header from '../../Components/Header/Header';
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { IoIosArrowDown } from "react-icons/io";
import { getCookieValue, URLS } from '../../utilities/constants';
import axios from 'axios';
import { DataContext } from '../../utilities/DataContext';
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { trackPageView } from '../../hooks/analytics';
import { getCookieConsentValue } from 'react-cookie-consent';




const AdsList = () => {
    const navigate = useNavigate();
    const [addsList, setAddsList] = useState([])
    const [activeAdds, setActiveAdds] = useState([])
    const [InprogressAdds, setInprogressAdds] = useState([])
    const [activeAdsCount, setActiveAdsCount] = useState(0)
    const { token, addDetails, setAddDetails } = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const [analyticsData, setAnalyticsData] = useState({});

    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

    useEffect(() => {
        window.dataLayer.push({
            'event': 'gtag.init',
            'gtag.config': {
                trackingId: 'G-4CG75M28S5',
                send_page_view: false
            }
        });
    }, [window.location.pathname])


    useEffect(() => {
        document.title = 'Alle Anzeigen auf campervanspot.com'
        const consentCookie = getCookieValue('cookie_consent_level');
        if (consentCookie) {
            const consentStatus = JSON.parse(decodeURIComponent(consentCookie));
            console.log('consentStatus', consentStatus.tracking);
            if (consentStatus.tracking) {
                trackPageView(window.location.pathname, 'Alle Anzeigen auf campervanspot.com');
            }
        }

    }, [])

    useEffect(() => {
        // const array = [{ 'id': 12, 'vehiclename': 'Restrover - Special Edition' }, { 'id': 12, 'vehiclename': 'Horizon Master' }, { 'id': 12, 'vehiclename': 'Silent Hunter' }]
        // setAddsList(array)
        callAddsListApi()
        // callAddAnalyticsApi()
    }, []);


    const formatCurrency = (value, currency) => {
        console.log('currency', currency)
        if (value !== '' && currency !== '' && value !== null && currency !== null) {
            let locale = 'de-DE'; // German locale for both EUR and CHF
            let formattedValue = new Intl.NumberFormat(locale, {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
            return `${formattedValue} ${currency.toUpperCase()}`;
        } else {
            return ''
        }

    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}.${month}`;
    };

    const createAddBtnClick = () => {
        setAddDetails({})
        navigate('/adcreate')
    }

    const callAddAnalyticsApi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ADD_ANALYTICS}`;
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response.data.data
                    setAnalyticsData(data)
                } else {
                    console.log('response', response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                alert(error.response.data.message);
            });
    }

    const callAddsListApi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ADDS_LIST}`;
        await axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response.data.data
                    const activeads = data.filter(add => add.publish_status === 'published');
                    const inactiveAdds = data.filter(add => add.publish_status !== 'published');
                    setActiveAdds(activeads)
                    setActiveAdsCount(activeads.length)
                    setInprogressAdds(inactiveAdds)
                    setAddsList(data)

                } else {
                    console.log('response', response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                alert(error.response.data.message);
            });
    }

    const handlePublishClick = (addStatus) => {
        // const status = addDetails.vehicle_ad_overview_data.publish_status
        let status = addStatus === 'inprogress' ? 'publish' : addStatus === 'published' ? 'unpublish' : addStatus === 'unpublished' ? 'republish' : ''
        callAddPublishApi(status)
    }

    const callAddPublishApi = async (status) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ADD_PUBLISH}${addDetails.vehicle_ad_overview_data.id}/?publish_status=${status}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios
            .get(url, { headers: { Authorization: token } })
            .then((response) => {
                console.log("response", response);
                setLoading(false);
                if (response?.data.status === 1) {
                    callAddsListApi()
                } else {
                    console.log("response", response);
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    return (
        <>
            <Header />
            {loading && (
                <div className="loader">
                    <ClipLoader
                        color={"#123abc"}
                        loading={loading}
                        css={override}
                        size={50}
                    />
                </div>
            )}
            <div style={{ height: "100vh" }}>
                <div className='maindiv'>
                    <h1 className="headertext_1 mt3 mb4">
                        Dashboard
                    </h1>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        <div className='analytics_layout'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='analytics_header'>Impressionen</span>
                                <span className='analytics_number'>0</span>
                            </div>
                            <div className='analytics_border' />
                        </div>

                        <div className='analytics_layout'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='analytics_header'>Aktive Benutzer</span>
                                <span className='analytics_number'>0</span>
                            </div>
                            <div className='analytics_border' />
                        </div>

                        <div className='analytics_layout'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='analytics_header'>Durchschnittliche Sitzungsdauer</span>
                                <span className='analytics_number'>0</span>
                            </div>
                            <div className='analytics_border' />
                        </div>

                        <div className='analytics_layout'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='analytics_header'>Anzeigen</span>
                                <span className='analytics_number'>{activeAdsCount}</span>
                            </div>
                            <div className='analytics_border' />
                        </div>
                    </div>

                    <Row style={{ marginTop: '2vmin' }}>
                        {/* <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <h2 className="headertext_2  mt3 mb4">
                                        Nachrichten
                                    </h2>
                                </Col>
                                <Col md={4}>
                                    <button className='btn_7days'>Alle ansehen</button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <p>
                                        Dein Posteingang ist leer!
                                        Erstelle eine Anzeige, um mit Kunden in Kontakt zu treten.
                                    </p>
                                </Col>
                            </Row>
                            <button className="add_btn" onClick={createAddBtnClick}>+ Anzeige</button>
                        </Col> */}
                        <Col md={10} lg={7} xxl={6} xl={6} xs={12} sm={12} >
                            <Row>
                                <Col md={8}>
                                    <h2 className="headertext_2  mt3 mb4">
                                        Aktive Anzeigen
                                    </h2>
                                </Col>
                                {/* <Col md={4}>
                                    <button className='btn_7days'>Alle ansehen</button>
                                </Col> */}
                            </Row>
                            <div>
                                {activeAdds.length == 0 ?
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <p>
                                                    Du hast noch keine Anzeige aktiv!
                                                    Erstelle deine erste Anzeige und begeistere deine Kunden.
                                                </p>
                                            </Col>
                                        </Row>

                                    </div> :
                                    <div className='adds_section_cards'>
                                        {activeAdds.map(add => (
                                            <Row className='card_content row_add' id={`${add.id}`} onClick={() => {
                                                setAddDetails({})
                                                navigate('/adcreate', { state: { id: add.id } })
                                            }}>
                                                <Col md={3} xs={3} xl={2} xxl={2} lg={3} sm={3} className='col_add' style={{ borderRadius: 10, display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 10, justifyContent: 'center' }}>
                                                    <img style={{ width: '100%', objectFit: 'contain', borderRadius: 10, maxHeight: 100, borderRadius: 10 }} src={add.hero_image !== null ? add.hero_image : require('../../assets/add_placeholder.jpeg')} />
                                                    <button className="unpublish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePublishClick(add.publish_status);
                                                    }}> <span>Pausieren</span></button>
                                                </Col>
                                                <Col md={8} xxl={9} xl={9} lg={8} sm={8} xs={8} className='col_add' style={{ marginLeft: 5, marginRight: 5 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
                                                        <span className='add_name'>{add.vehicle_name}</span>
                                                        <span className='add_date' style={{ marginLeft: 10, marginRight: 10 }}>{`${formatDate(add.publish_date)}-${formatDate(add.end_date)}`}</span>
                                                        {/* <span className='add_date' style={{ marginLeft: 10 }}>01.02 - 30.03</span> */}
                                                        <span className='add_name' style={{ marginRight: 10 }}>{formatCurrency(add.price, add.currency)}</span>
                                                    </div>
                                                    <Row style={{ marginBottom: 10, marginLeft: 10, marginRight: 10 }}>
                                                        <Col xxl={3} xl={5} lg={5} md={5} xs={5} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                <span className='add_name'>0</span>
                                                                <span className='add_date'>Impressionen</span>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3} xl={5} md={5} lg={5} xs={5} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                <span className='add_name'>0</span>
                                                                <span className='add_date'>Aktive Benutzer</span>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={5} xl={9} md={7} lg={8} xs={10} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                <span className='add_name'>0</span>
                                                                <span className='add_date'>Durchschnittliche Sitzungsdauer</span>
                                                            </div>
                                                        </Col>
                                                        {/* <Col md={3} xs={3} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                <span className='add_name'>--</span>
                                                                <span className='add_date'>Nachrichten</span>
                                                            </div>
                                                        </Col> */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>}
                                <button className="add_btn2" onClick={createAddBtnClick}>+ Anzeige erstellen</button>
                                {/* {addsList.length > 0 &&
                                    <div className='cardcontainer'>
                                        <div className='section_cards'>
                                            {addsList.map(add => (
                                                <div className='imagecard' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/placeholder.webp"})` }}>
                                                    <div className='content'>
                                                        <div className='text'>
                                                            <div className='title'>
                                                                {add.vehiclename}
                                                            </div>
                                                        </div>
                                                        <div onClick={() => nextClicked(add)} className="arrow-icon">
                                                            <BsArrowRight size={30} style={{ color: 'white' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                } */}
                            </div>
                            {InprogressAdds.length > 0 &&
                                <div style={{ marginTop: 20 }}>
                                    <h2 className="headertext_2  mt3 mb4">
                                        Inaktive Anzeigen
                                    </h2>
                                    <div className='adds_section_cards'>
                                        {InprogressAdds.map(add => (
                                            <Row className='card_content row_add' id={`${add.id}`} onClick={() => {
                                                setAddDetails({})
                                                navigate('/adcreate', { state: { id: add.id } })
                                            }}>
                                                <Col md={3} xs={5} xl={2} xxl={2} lg={3} sm={3} className='col_add' style={{ borderRadius: 10, display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 10, justifyContent: 'center' }}>
                                                    <img style={{ width: '100%', objectFit: 'contain', borderRadius: 10, maxHeight: 100, borderRadius: 10 }} src={add.hero_image !== null ? add.hero_image : require('../../assets/add_placeholder.jpeg')} />
                                                    <button className="publish_btn" onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePublishClick(add.publish_status)
                                                    }}> <span>{add.publish_status == 'inprogress' ? 'Veröffentlichen' : add.publish_status == 'unpublished' ? 'Erneut veröffentlichen' : ''}</span></button>
                                                </Col>
                                                <Col md={8} xxl={9} xl={9} lg={8} sm={8} xs={6} className='col_add' style={{ marginLeft: 5, marginRight: 5 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
                                                        <span style={{ marginLeft: 10 }} className='add_name'>{add.vehicle_name}</span>
                                                        <span className='add_date' style={{ marginLeft: 10 }}>{add.publish_date !== null ? `${formatDate(add.publish_date)}-${formatDate(add.end_date)}` : ''}</span>
                                                        {/* <span className='add_date' style={{ marginLeft: 10 }}>01.02 - 30.03</span> */}
                                                        <span className='add_name'>{formatCurrency(add.price, add.currency)}</span>
                                                    </div>
                                                    <Row style={{ marginBottom: 10, marginLeft: 10, marginRight: 10 }}>
                                                        <Col xxl={5} xl={5} lg={5} md={5} xs={4} sm={4} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                {/* <span className='add_name'>{add.ad_analytics_data.impressions}</span> */}
                                                                <span className='add_name'>0</span>
                                                                <span className='add_date'>Impressionen</span>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={5} xl={5} md={5} lg={5} xs={6} sm={4} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                {/* <span className='add_name'>{add.ad_analytics_data.active_users}</span> */}
                                                                <span className='add_name'>0</span>
                                                                <span className='add_date'>Aktive Benutzer</span>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={6} xl={6} md={7} lg={8} xs={12} sm={8} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                {/* <span className='add_name'>{Math.round(add.ad_analytics_data.average_session_duration)}</span> */}
                                                                <span className='add_name'>0</span>
                                                                <span className='add_date'>Durchschnittliche Sitzungsdauer</span>
                                                            </div>
                                                        </Col>
                                                        {/* <Col md={3} xs={6} className='col_add'>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 5 }}>
                                                                <span className='add_name'>--</span>
                                                                <span className='add_date'>Nachrichten</span>
                                                            </div>
                                                        </Col> */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                    <button className="add_btn2" onClick={createAddBtnClick}>+ Anzeige erstellen</button>
                                </div>

                            }

                        </Col>
                    </Row>
                    {/* <Row style={{ marginTop: '5vmin' }}>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <h2 className="headertext_2  mt3 mb4">
                                        Profil
                                    </h2>
                                </Col>
                                <Col md={4}>
                                    <button className='btn_7days'>Bearbeiten | Besuchen</button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '2vmin' }}>
                                <Col md={11}>
                                    <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/adsdashboardprofile.png"})`, backgroundSize: 'contain', backgroundPosition: 'center', aspectRatio: '1/0.8', }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
                    <button className="enquire_btn" onClick={createAddBtnClick}>Erstellen Hinzufügen</button>
                </div> */}
            </div >
        </>
    )
}

export default AdsList;